<template>
  <div
    v-if="hasValidTestimonials"
    class="grid grid-cols-1 gap-6 md:gap-8 md:grid-cols-2 lg:grid-cols-3"
  >
    <TestimonialCard
      v-for="(testimonial, key) in testimonials"
      :key="`testimonial_${key}`"
      v-bind="testimonial"
    />
  </div>
</template>

<script>
export default {
  name: 'TestimonialCards',
  props: {
    testimonials: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasValidTestimonials() {
      return (
        this.testimonials &&
        Array.isArray(this.testimonials) &&
        this.testimonials.length > 0
      )
    }
  }
}
</script>
